<template>
  <el-form :model="form" :rules="rules" ref="form" label-width="150px" size="mini">
    <el-row v-if="!isShowEditHetongGongdi">
      <el-col :span="12">
        <el-form-item label="项目或工地">
          <el-input v-model="form.contract_hetong_gongdi" readonly>
            <template slot="append"
              ><el-button @click="editHetong_gongdi">修改合同</el-button></template
            >
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="所属材料通知单编号">
          <el-select v-model="form.cailiaotongzhidan">
            <el-option v-for="item in cailiaotongzhidans_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row v-if="isShowEditHetongGongdi">
      <el-col :span="12">
        <el-form-item label="项目或工地" prop="contract" :rules="{ required: true, message: '请输入合同编号或工地', trigger: 'blur' }">
          <el-select
            v-model="form.contract"
            filterable
            remote
            placeholder="项目或工地"
            :remote-method="remoteMethod"
            :loading="loading"
            @change="getCailiaotongzhidans"
          >
            <el-option v-for="item in XiangmuGongdiOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="12">
        <el-form-item label="所属材料通知单编号" prop="cailiaotongzhidan">
          <el-select v-model="form.cailiaotongzhidan" placeholder="请选择材料通知单" @change="setDiaobodanCode($event)">
            <el-option v-for="item in cailiaotongzhidans_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="调拨单编号" prop="code">
          <el-input v-model="form.code"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="出入库房" prop="cangku">
          <el-select v-model="form.cangku" placeholder="请选择所属仓库">
            <el-option v-for="item in cangkus_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="12">
        <el-form-item label="材料名称" prop="cailiao">
          <el-select v-model="form.cailiao" placeholder="请选择材料名称">
            <el-option v-for="item in cailiaos_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="材料规格" prop="cailiaoguige">
          <el-select v-model="form.cailiaoguige" placeholder="请选择材料规格">
            <el-option v-for="item in cailiaoguiges_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="日期" prop="date">
          <el-date-picker type="date" :picker-options="pickerOptions" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="form.date" style="width: 100%;">
          </el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="数量" prop="amount">
          <el-input v-model.number="form.amount"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-form-item label="备注" prop="remark">
      <el-input type="textarea" rows="3" v-model="form.remark"></el-input>
    </el-form-item>
    <el-form-item>
      <div style="float:right;">
        <el-button @click="resetForm('form')"> 取消 </el-button>
        <el-button type="primary" @click="submitForm('form')"> 确认修改 </el-button>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: {
    formData: Object
  },
  data() {
    return {
      form: {},
      XiangmuGongdiOptions: [],
      loading: false,
      cangkus_options: [],
      cailiaos_options: [],
      cailiaoguiges_options: [],
      cailiaotongzhidans_options: [],
      isShowEditHetongGongdi: false,

      pickerOptions: {
        // 限制只能选择今天之前的日期
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      rules: {
        cailiaotongzhidan: [{ required: true, message: '请输入材料通知单编号', trigger: 'blur' }],
        code: [{ required: true, message: '请输入调拨单编号', trigger: 'blur' }],
        cangku: [{ required: true, message: '请选择所属仓库', trigger: 'blur' }],
        cailiao: [{ required: true, message: '请选择材料名称', trigger: 'blur' }],
        cailiaoguige: [{ required: true, message: '请选择材料规格', trigger: 'blur' }],
        amount: [{ required: true, message: '请输入数量', trigger: 'blur' }, { type: 'number', message: '数量必须为数字' }],
        date: [{ required: true, message: '请选择调配时间', trigger: 'blur' }]
      }
    }
  },
  methods: {
    //项目工地搜索框响应事件，从后台查询10条记录
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          const api = '/getXiangmuGongdis/'
          this.$axios
            .get(api, {
              params: {
                query: query
              }
            })
            .then(res => {
              if (res.data.code === 1000) {
                this.XiangmuGongdiOptions = res.data.data.map(item => {
                  item.label = item.contract_id + '#' + item.project
                  item.value = item.id
                  return item
                })
              } else {
                console.log('没有项目工地信息！')
              }

              console.log('XiangmuGongdiOptions', this.XiangmuGongdiOptions)
            })
        }, 200)
      } else {
        this.XiangmuGongdiOptions = []
      }
    },
    //根据选择的项目工地（合同编号）取得该合同的所有材料通知单
    getCailiaotongzhidans(type = 0) {
      const api = '/getCailiaotongzhidanByIDforDiaobodan/'
      let id = ''
      if (type === 1) id = this.formData.contract
      else id = this.form.contract
      this.$axios
        .get(api, {
          params: {
            id: id
          }
        })
        .then(res => {
          if (res.data.code === 1000) {
            this.cailiaotongzhidans_options = res.data.data.map(item => {
              item.label = item.code
              item.value = item.id
              return item
            })
          } else {
            console.log('没有该合同对应的材料通知单信息！')
          }

          console.log('cailiaotongzhidans_options', this.cailiaotongzhidans_options)
        })
    },

    getCangkus() {
      this.$axios.get('/getCangkus/').then(res => {
        console.log('getCangkus list:', res.data)
        res.data.forEach(element => {
          let cangku = {}
          cangku.label = String(element.name)
          cangku.value = element.id
          this.cangkus_options.push(cangku)
        })
        console.log('cangkus_options', this.cangkus_options)
      })
    },
    getCailiaos() {
      this.$axios.get('/getCailiaos/').then(res => {
        console.log('getCailiaos list:', res.data)
        res.data.forEach(element => {
          let cailiao = {}
          cailiao.label = String(element.name)
          cailiao.value = element.id
          this.cailiaos_options.push(cailiao)
        })
        console.log('cailiaos_options', this.cailiaos_options)
      })
    },
    getCailiaoguiges() {
      this.$axios.get('/getCailiaoguiges/').then(res => {
        console.log('getCailiaoguiges list:', res.data)
        res.data.forEach(element => {
          let cailiaoguige = {}
          cailiaoguige.label = String(element.name)
          cailiaoguige.value = element.id
          this.cailiaoguiges_options.push(cailiaoguige)
        })
        console.log('cailiaoguiges_options', this.cailiaoguiges_options)
      })
    },
    initform() {
      this.form = JSON.parse(JSON.stringify(this.formData)) //深拷贝，以防止修改父组件数据
      this.form.amount = Number(this.form.amount)
      console.log('editForm:', this.form)
    },

    submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          console.log('validation ok!')
          this.$emit('submitForm', this.form)
        } else {
          console.log('Validation error!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.$emit('resetForm')
    },
    fn1() {
      return new Promise(resolve => {
        setTimeout(() => {
          this.getCailiaotongzhidans(1)
          resolve()
        }, 300)
      })
    },
    fn2() {
      return new Promise(resolve => {
        setTimeout(() => {
          this.initform()
          resolve()
        }, 300)
      })
    },

    setDiaobodanCode(val) {
      console.log('cailiaotongzhidan:', val)
      let obj = this.cailiaotongzhidans_options.find(item => {
        return item.value === val
      })
      this.form.code = obj.label
    },
    editHetong_gongdi() {
      this.isShowEditHetongGongdi = true
      this.form.contract = ''
      this.form.cailiaotongzhidan = ''
    }
  },
  created() {
    this.getCangkus()
    this.getCailiaos()
    this.getCailiaoguiges()
    this.fn1().then(this.fn2)
  }
}
</script>

<style lang="scss" scoped></style>
