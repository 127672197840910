<template>
  <el-form :model="form" :rules="rules" ref="form" label-width="150px" size="mini">
    <el-row>
      <el-col :span="12">
        <el-form-item label="项目或工地" prop="contract" :rules="{ required: true, message: '请输入合同编号或工地', trigger: 'blur' }">
          <el-select
            v-model="form.contract"
            filterable
            remote
            placeholder="项目或工地"
            :remote-method="remoteMethod"
            :loading="loading"
            @change="getCailiaotongzhidans"
          >
            <el-option v-for="item in XiangmuGongdiOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="所属材料通知单编号" prop="cailiaotongzhidan">
          <el-select v-model="form.cailiaotongzhidan" placeholder="请选择材料通知单" @change="setDiaobodanCode($event)">
            <el-option v-for="item in cailiaotongzhidans_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="调拨单编号" prop="code">
          <el-input v-model="form.code"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="出入库房" prop="cangku">
          <el-select v-model="form.cangku" placeholder="请选择所属仓库">
            <el-option v-for="item in cangkus_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row> </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="材料名称" prop="cailiao">
          <el-select v-model="form.cailiao" placeholder="请选择材料名称">
            <el-option v-for="item in cailiaos_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="材料规格" prop="cailiaoguige">
          <el-select v-model="form.cailiaoguige" placeholder="请选择材料规格">
            <el-option v-for="item in cailiaoguiges_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="日期" prop="date">
          <el-date-picker type="date" :picker-options="pickerOptions" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="form.date" style="width: 100%;">
          </el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="数量" prop="amount">
          <el-input v-model.number="form.amount"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-form-item label="备注" prop="remark">
      <el-input type="textarea" rows="3" v-model="form.remark"></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: {
    form: Object
  },
  data() {
    return {
      XiangmuGongdiOptions: [],
      loading: false,
      isValidationOk: false,
      cangkus_options: [],
      cailiaos_options: [],
      cailiaoguiges_options: [],
      cailiaotongzhidans_options: [],

      pickerOptions: {
        // 限制只能选择今天之前的日期
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      rules: {
        cailiaotongzhidan: [{ required: true, message: '请输入材料通知单编号', trigger: 'blur' }],
        code: [{ required: true, message: '请输入调拨单编号', trigger: 'blur' }],
        cangku: [{ required: true, message: '请选择所属仓库', trigger: 'blur' }],
        cailiao: [{ required: true, message: '请选择材料名称', trigger: 'blur' }],
        cailiaoguige: [{ required: true, message: '请选择材料规格', trigger: 'blur' }],
        amount: [{ required: true, message: '请输入数量', trigger: 'blur' }, { type: 'number', message: '数量必须为数字' }],
        date: [{ required: true, message: '请选择调配时间', trigger: 'blur' }]
      }
    }
  },
  methods: {
    //项目工地搜索框响应事件，从后台查询10条记录
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          const api = '/getXiangmuGongdis/'
          this.$axios
            .get(api, {
              params: {
                query: query
              }
            })
            .then(res => {
              if (res.data.code === 1000) {
                this.XiangmuGongdiOptions = res.data.data.map(item => {
                  item.label = item.contract_id + '#' + item.project
                  item.value = item.id
                  return item
                })
              } else {
                console.log('没有项目工地信息！')
              }

              console.log('XiangmuGongdiOptions', this.XiangmuGongdiOptions)
            })
        }, 200)
      } else {
        this.XiangmuGongdiOptions = []
      }
    },
    //根据选择的项目工地（合同编号）取得该合同的所有材料通知单
    getCailiaotongzhidans() {
      const api = '/getCailiaotongzhidanByIDforDiaobodan/'
      this.$axios
        .get(api, {
          params: {
            id: this.form.contract
          }
        })
        .then(res => {
          if (res.data.code === 1000) {
            this.cailiaotongzhidans_options = res.data.data.map(item => {
              item.label = item.code
              item.value = item.id
              return item
            })
          } else {
            console.log('没有该合同对应的材料通知单信息！')
          }

          console.log('cailiaotongzhidans_options', this.cailiaotongzhidans_options)
        })
    },

    getCangkus() {
      this.$axios.get('/getCangkus/').then(res => {
        console.log('getCangkus list:', res.data)
        res.data.forEach(element => {
          let cangku = {}
          cangku.label = String(element.name)
          cangku.value = element.id
          this.cangkus_options.push(cangku)
        })
        console.log('cangkus_options', this.cangkus_options)
      })
    },
    getCailiaos() {
      this.$axios.get('/getCailiaos/').then(res => {
        console.log('getCailiaos list:', res.data)
        res.data.forEach(element => {
          let cailiao = {}
          cailiao.label = String(element.name)
          cailiao.value = element.id
          this.cailiaos_options.push(cailiao)
        })
        console.log('cailiaos_options', this.cailiaos_options)
      })
    },
    getCailiaoguiges() {
      this.$axios.get('/getCailiaoguiges/').then(res => {
        console.log('getCailiaoguiges list:', res.data)
        res.data.forEach(element => {
          let cailiaoguige = {}
          cailiaoguige.label = String(element.name)
          cailiaoguige.value = element.id
          this.cailiaoguiges_options.push(cailiaoguige)
        })
        console.log('cailiaoguiges_options', this.cailiaoguiges_options)
      })
    },
    submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          console.log('validation ok!')
          this.isValidationOk = true
        } else {
          console.log('Validation error!')
          this.isValidationOk = false
          return false
        }
      })
    },
    resetForm() {
      this.$refs['from'].resetFields()
    },
    setDiaobodanCode(val) {
      console.log('cailiaotongzhidan:', val)
      let obj = this.cailiaotongzhidans_options.find(item => {
        return item.value === val
      })
      this.form.code = obj.label
    }
  },
  created() {
    this.getCangkus(), this.getCailiaos(), this.getCailiaoguiges()
  }
}
</script>

<style lang="scss" scoped></style>
