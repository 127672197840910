<template>
  <div class="manage">
    <el-dialog width="600px" title="新增调拨单" :visible.sync="diaobodanDlg_isShow" :close-on-click-modal="false">
      <diaobodan-form :form="diaobodanForm" ref="diaobodanForm"></diaobodan-form>
      <div slot="footer">
        <el-button @click="diaobodanDlg_isShow = false"> 取消 </el-button>
        <el-button type="primary" @click="submitDiaobodanForm('diaobodanForm')"> 确认添加 </el-button>
      </div>
    </el-dialog>
    <el-dialog width="800px" title="修改调拨单" :visible.sync="diaobodanDlgEdit_isShow" v-if="diaobodanDlgEdit_isShow" :close-on-click-modal="false">
      <diaobodanedit-form
        :formData="diaobodanEditForm"
        @submitForm="submitDiaobodanEditForm"
        @resetForm="reseteditForm"
        ref="diaobodanEditForm"
      ></diaobodanedit-form>
    </el-dialog>
    <div class="manage-header">
      <div class="left">
        <span style="font-weight:bold;">调拨单列表</span>
      </div>
      <div class="right">
        <input
          class="searchinput"
          type="text"
          v-model="keyword"
          placeholder="编号 | 合同编号 | 合同名称 | 乙方签约人 | 库房 | 规格"
          @keyup.enter="getKeyword"
        />
        <button class="searchbutton" @click="getKeyword">搜索</button>
      </div>
    </div>

    <el-button plain v-if="usertype.code === '4'" type="text" size="medium" @click="addDiaobodan">新 增</el-button>

    <diaobodan-table
      :tableData="diaobodanTableData"
      :config="config"
      :userType="usertype"
      :isShowHeyueField="false"
      @changePage="getDiaobodans"
      @del="deleteDiaobodan"
      @edit="editDiaobodan"
      @caiwuAudit="caiwuAuditDiaobodan"
      @kuguanConfirm="confirmDiaobodan"
    ></diaobodan-table>
  </div>
</template>

<script>
// import CommonForm from '../../components/CommonForm'

import utils from '@/assets/js/utils'
import DiaobodanTable from './DiaobodanTable'
import DiaobodanForm from './DiaobodanForm'
import DiaobodaneditForm from './DiaobodanEditForm'

// import Cookie from 'js-cookie'

export default {
  components: {
    // CommonForm,
    DiaobodanTable,
    DiaobodanForm,
    DiaobodaneditForm
  },
  data() {
    return {
      diaobodanTableData: [],
      diaobodanDlg_isShow: false,
      diaobodanDlgEdit_isShow: false,
      diaobodanForm: {},
      diaobodanEditForm: {},

      usertype: '',
      user_realname: '',
      currentUserCompany: utils.getUserCookie().company.code,

      keyword: '',

      config: {
        page: 1,
        total: 0,
        loading: false
      }
    }
  },
  watch: {
    'config.page': function() {
      utils.setPageCookie(this.config.page)
    }
  },

  methods: {
    //获取搜索关键词
    getKeyword() {
      // alert(this.keyword)
      // utils.setKeywordCookie(this.keyword)
      this.config.page = 1
      // console.log('keyword_cookie', this.keyword)
      this.getDiaobodans()
    },

    getSelectedRows(val) {
      console.log('Selected rows:', val)
    },

    //获取调拨单信息
    getDiaobodans() {
      this.config.loading = true
      console.log('调拨单列表')
      // this.keyword = utils.getKeywordCookie()
      //获取当前用户信息：姓名，用户类型
      let user = utils.getUserCookie()
      this.usertype = user.user_type
      this.user_realname = user.name
      console.log('user:', user)
      const api = '/getDiaobodans/'
      this.$axios
        .get(api, {
          params: {
            page: this.config.page,
            currentUserKufang: this.currentUserCompany, //当前用户所属库房编号code
            keyword: this.keyword
          }
        })
        .then(res => {
          if (res.data.code === '1000') {
            console.log('res.data', res.data.data)
            this.config.total = res.data.totalcount
            console.log('total:', this.config.total)
            this.diaobodanTableData = res.data.data.map(item => {
              item.contract_hetong_gongdi = item.contract_id + item.contract_project
              item.added = item.added_person + '\n' + utils.formatDateTime(item.added_person_time, 'yyyy-MM-dd hh:mm')
              let in_amount = parseInt(item.amount) - parseInt(item.out_amount)
              item.in_amount = in_amount
              return item
            })
          } else {
            console.log('没有调拨单信息！')
          }
          this.config.loading = false
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    //增加调拨单
    addDiaobodan() {
      this.diaobodanForm = {}
      this.diaobodan_operateType = 'add'
      this.diaobodanDlg_isShow = true
    },
    //修改调拨单
    editDiaobodan(row) {
      this.diaobodanEditForm = row
      console.log('diaobodanEditForm:', this.diaobodanEditForm)
      this.diaobodanDlgEdit_isShow = true
    },
    //增加调拨单表单提交
    submitDiaobodanForm(formName) {
      console.log(formName)
      let form = this.$refs[formName]
      form.submitForm()
      let user = utils.getUserCookie()
      this.usertype = user.user_type
      this.user_realname = user.name
      if (form.isValidationOk) {
        console.log('add:' + this.Type)

        this.diaobodanForm.added_person = this.user_realname
        // this.chukuForm.added_person_time = utils.getDateTime()
        console.log('diaobodanForm:', this.diaobodanForm)
        this.$axios.post('/addDiaobodan/', this.diaobodanForm).then(res => {
          console.log('response:', res.data)
          if (res.data.code === 1000) {
            this.$message({
              type: 'success',
              message: '增加调拨单成功!',
              duration: 2000
            })
            this.diaobodanDlg_isShow = false
            this.getDiaobodans()
          } else {
            this.$message({
              type: 'warning',
              message: '请核对材料通知单输入是否正确！',
              duration: 4000
            })
            console.log(res.data.msg)
          }
        })
      }
    },
    //修改调拨单表单提交
    submitDiaobodanEditForm(formData) {
      this.diaobodanEditForm = formData
      console.log('diaobodanEditForm:', this.diaobodanEditForm)
      this.$axios.put('/updateDiaobodan/', this.diaobodanEditForm).then(res => {
        console.log('response:', res.data)
        if (res.data.code === 1000) {
          this.$message({
            type: 'success',
            message: '修改调拨单成功!',
            duration: 2000
          })
          this.diaobodanDlgEdit_isShow = false
          this.getDiaobodans()
        } else {
          this.$message({
            type: 'warning',
            message: '请核对调拨单输入是否正确！',
            duration: 4000
          })
          console.log(res.data.msg)
        }
      })
    },
    reseteditForm() {
      this.diaobodanDlgEdit_isShow = false
      this.diaobodanEditForm = {}
    },
    //删除调拨单信息
    deleteDiaobodan(row) {
      this.$confirm('此操作将永久删除此调拨单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let id = row.id
          this.$axios
            .get('/deleteDiaobodan/', {
              params: {
                id
              }
            })
            .then(res => {
              console.log(res.data)
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.getDiaobodans()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    /*
    财务审核
    */
    caiwuAuditDiaobodan(row) {
      this.$confirm('确认通过财务审核吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // let operateTime = utils.getDateTime()
          // console.log(operateTime)
          let params = {
            id: row.id
          }
          this.$axios.put('/caiwuAuditDiaobodan/', params).then(res => {
            console.log(res.data)
            this.$message({
              type: 'success',
              message: '财务审核成功!'
            })
            this.getDiaobodans()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消财务审核'
          })
        })
    },
    /*
    库管确认
    */
    confirmDiaobodan(row) {
      this.$confirm('确认通过确认吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // let operateTime = utils.getDateTime()
          // console.log(operateTime)
          let params = {
            id: row.id
          }
          this.$axios.put('/confirmDiaobodan/', params).then(res => {
            console.log(res.data)
            this.$message({
              type: 'success',
              message: '确认成功!'
            })
            this.getDiaobodans()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消确认'
          })
        })
    }
  },
  created() {
    this.getDiaobodans()
  }
}
</script>
<style scoped></style>
