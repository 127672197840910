import { render, staticRenderFns } from "./DiaobodanTable.vue?vue&type=template&id=0d03eb66&scoped=true&"
import script from "./DiaobodanTable.vue?vue&type=script&lang=js&"
export * from "./DiaobodanTable.vue?vue&type=script&lang=js&"
import style1 from "./DiaobodanTable.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d03eb66",
  null
  
)

export default component.exports